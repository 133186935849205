import React from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Checkbox, Menu, Typography, Tooltip as MuiTooltip, Button, TextField, DialogActions, Tab, Tabs, Box, Switch, FormGroup, FormControlLabel, IconButton } from '@mui/material'
import { DangerousOutlined, CheckCircleOutline, Sync, SyncDisabledOutlined, DateRangeOutlined, Timeline, PieChart, SensorOccupied } from '@mui/icons-material'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { C, D, getColors, getColorsByClass } from '../include'
import { Filter } from './Filter'; 
import _ from 'underscore'
import moment from 'moment'
moment.locale('pt_br')
ChartJS.register(ArcElement, Tooltip, Legend)
class DashboardCard extends React.Component {
  render() {
    return (
      <D className="col e aF" style={{
        margin: '5px',
        backgroundColor: '#eeeeee',
        borderRadius: '0.3rem',
        padding: '0.3rem 0.5rem'
      }}>
        
        <D className="row " >
            <Typography variant="body2" component="div" align="center" color="text.secondary" noWrap >{this.props.title}</Typography>
        </D>
        <D className="row">
            <D className="row aC " style={{ backgroundColor: this.props.color }}>
                {this.props.icon}
            </D>
            <D size="0.5rem" />
            <D className="row e aE">
                <Typography variant="body2" component="div" align="left" color="text.secondary">{this.props.value}</Typography>
           </D>
        </D>
        
        

      </D>
    )
  }
}
class DashboardGraph extends React.Component {
  render() {
    return (
      <D className="col e aC" style={{ padding: '0.5rem' }}>
        <D size="350px" >
          <Typography align="center" variant="subtitle1" color="textSecondary">{this.props.title}</Typography>
          <Doughnut data={this.props.data} options={{
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: !!this.props.legendPosition ? this.props.legendPosition : 'right',
              }
            }
          }} />
        </D>
      </D>
    )
  }
}
 class DashboardTabPanel extends React.Component {
   render() {
     const { children, value, index, ...other } = this.props;
     return (
         <div
           role="tabpanel"
           hidden={value !== index}
           id={`simple-tabpanel-${index}`}
           aria-labelledby={`simple-tab-${index}`}
           {...other}
         >
           {value === index && (
             <Box sx={{ p: 3 }}>
               {children}
             </Box>
           )}
        </div>
     )
   }
 }
export class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.getStats = this.getStats.bind(this)
    this.applyFilter = this.applyFilter.bind(this)
    this.updateDash = this.updateDash.bind(this)
    this.updateDashUnread = this.updateDashUnread.bind(this)
    this.updateDashData = this.updateDashData.bind(this)
    this.state = {
      //dataini: "1950-01-01",
      dataini: new Date(new Date().setFullYear(new Date().getFullYear() - 2)).toISOString().substring(0, 10),
      datafim: new Date().toISOString().substring(0, 10),
      rawdcs: [],
      rawdcs_unread: [],
      filtereddcs: [],
      stats: { unread: 0, proc: 0, nproc: 0, onproc: 0, procerr: 0, total: 0 },
      boats: { labels: ['Outros'], datasets: [{ data: [0] }] },
      docs: { labels: ['Outros'], datasets: [{ data: [0] }] },
      suppliers: { labels: ['Outros'], datasets: [{ data: [0] }] },
      groupswithpendency: { labels: ['Outros'], datasets: [{ data: [0] }] },
      boatswithpendency: { labels: ['Outros'], datasets: [{ data: [0] }] },
      docs_unread: { labels: ['Outros'], datasets: [{ data: [0] }] },
      region_unread: { labels: ['Outros'], datasets: [{ data: [0] }] },
      docsMenu: null,
      fornecMenu: null,
      grupoPendenciaMenu: null,
      grupoEmbarcacaoMenu: null,
      embMenu: null,
      docsUnreadMenu: null,
      regUnreadMenu: null,
      tiposDeDocs: [],
      tiposDeFornec: [],
      tiposDeGrupoPendencia: [],
      tiposDeEmbarcacaoPendencia: [],
      tiposDeEmb: [],
      tiposDeDocsUnread: [],
      tiposDeRegUnread: [],
      aproxIniDateMenu: null,
      aproxFimDateMenu: null,
      selectedDocs: [],
      selectedFornec: [],
      selectedGrupoPendencia: [],
      selectedEmbarcacaoPendencia: [],
      selectedEmb: [],
      selectedDocsUnread: [],
      selectedRegUnread: [],
      selectedTab: 0,
      showUnread: false
    }
  }
  componentDidMount = () => this.updateDashData()
  updateDashData = () => {
    this.props.api.get('dashboard/stats').then(r => {
      let dcs = []
      let dcs_unread = []
      r.docs.forEach(d => {
        dcs.push({
          doc_id: d.doc_id,
          status: d.status,
          dtaprox: !!d.dtaprox ? moment(d.dtaprox, 'DD/MM/YYYY') : moment(d.created_at),
          doctype: !!d.doctype ? d.doctype : 'Sem tipo',
          supplier: !!d.supplier ? d.supplier : 'Sem fornecedor',
          boat: !!d.boat ? d.boat : 'Sem embarcação',
          groupwithpendency: !!d.groupwithpendency ? d.groupwithpendency : 'Sem pendência',
          boatwithpendency: !!d.boatwithpendency ? d.boatwithpendency : 'Sem pendência',
          rd_ctd: d.rd_ctd
        })
        if(d.rd_ctd == 0){
          dcs_unread.push({
            dtaprox: !!d.dtaprox ? moment(d.dtaprox, 'DD/MM/YYYY') : moment(d.created_at),
            doctype_unread: !!d.doctype ? d.doctype : 'Sem tipo',
            region_unread: !!d.region ? d.region : 'Sem região'
          })
        }
      })
      let tiposDeDocs = _.uniq(_.pluck(dcs, 'doctype')).sort()
      let tiposDeFornec = _.uniq(_.pluck(dcs, 'supplier')).sort()
      let tiposDeGrupoPendencia = _.uniq(_.pluck(dcs, 'groupwithpendency')).sort()
      let tiposDeEmbarcacaoPendencia = _.uniq(_.pluck(dcs, 'boatwithpendency')).sort()
      let tiposDeEmb = _.uniq(_.pluck(dcs, 'boat')).sort()
      let tiposDeDocsUnread = _.uniq(_.pluck(dcs_unread, 'doctype_unread')).sort()
      let tiposDeRegUnread = _.uniq(_.pluck(dcs_unread, 'region_unread')).sort()
      this.setState({
        rawdcs: dcs,
        rawdcs_unread: dcs_unread,
        tiposDeDocs: tiposDeDocs,
        tiposDeFornec: tiposDeFornec,
        tiposDeGrupoPendencia: tiposDeGrupoPendencia,
        tiposDeEmbarcacaoPendencia: tiposDeEmbarcacaoPendencia,
        tiposDeEmb: tiposDeEmb,
        tiposDeDocsUnread: tiposDeDocsUnread,
        tiposDeRegUnread: tiposDeRegUnread
      })
      this.applyFilter({
        docs: dcs,
        docs_unread: dcs_unread,
        selectedDocs: tiposDeDocs,
        selectedFornec: tiposDeFornec,
        selectedGrupoPendencia: tiposDeGrupoPendencia,
        selectedEmbarcacaoPendencia: tiposDeEmbarcacaoPendencia,
        selectedEmb: tiposDeEmb,
        selectedDocsUnread: tiposDeDocsUnread,
        selectedRegUnread: tiposDeRegUnread
      })
    })
  }
  applyFilter = o => {
    let fts = {
      dataini: !!o.dataini ? o.dataini : this.state.dataini,
      datafim: !!o.datafim ? o.datafim : this.state.datafim,
      selectedDocs: !!o.selectedDocs ? o.selectedDocs : this.state.selectedDocs,
      selectedFornec: !!o.selectedFornec ? o.selectedFornec : this.state.selectedFornec,
      selectedGrupoPendencia: !!o.selectedGrupoPendencia ? o.selectedGrupoPendencia : this.state.selectedGrupoPendencia,
      selectedEmbarcacaoPendencia: !!o.selectedEmbarcacaoPendencia ? o.selectedEmbarcacaoPendencia : this.state.selectedEmbarcacaoPendencia,
      selectedEmb: !!o.selectedEmb ? o.selectedEmb : this.state.selectedEmb,
      selectedDocsUnread: !!o.selectedDocsUnread ? o.selectedDocsUnread : this.state.selectedDocsUnread,
      selectedRegUnread: !!o.selectedRegUnread ? o.selectedRegUnread : this.state.selectedRegUnread
    }
    this.setState(fts)
    let fd = [], dcs = !!o.docs ? o.docs : this.state.rawdcs
    dcs.length > 0 && dcs.forEach(d => {
      d.dtaprox.isAfter(fts.dataini, 'YYYY-MM-DD')
        && d.dtaprox.isBefore(fts.datafim, 'YYYY-MM-DD')
        && fts.selectedDocs.includes(d.doctype)
        && fts.selectedFornec.includes(d.supplier)
        && fts.selectedGrupoPendencia.includes(d.groupwithpendency)
        && fts.selectedEmbarcacaoPendencia.includes(d.boatwithpendency)
        && fts.selectedEmb.includes(d.boat)
        && fd.push(d)
    })
    let fd_unread = [], dcs_unread = !!o.docs_unread ? o.docs_unread : this.state.rawdcs_unread
    dcs_unread.length > 0 && dcs_unread.forEach(d => {
       d.dtaprox.isAfter(fts.dataini, 'YYYY-MM-DD')
         && d.dtaprox.isBefore(fts.datafim, 'YYYY-MM-DD')
         && fts.selectedDocsUnread.includes(d.doctype_unread)
         && fts.selectedRegUnread.includes(d.region_unread)
         && fd_unread.push(d)
     })
    this.updateDash(fd)
    this.updateDashUnread(fd_unread)
  }
  getStats = dcs => {
    let stats = { unread: 0, proc: 0, nproc: 0, onproc: 0, procerr: 0, total: 0 }
    !!dcs && dcs.length > 0 && dcs.forEach(d => {
      if (d.status === 'processado') {
        stats.proc += 1
        stats.total += 1
      } else if (d.status === 'não processado') {
        stats.nproc += 1
        stats.total += 1
      } else if (d.status === 'em processamento') {
        stats.onproc += 1
        stats.total += 1
      } else if (d.status === 'erro') {
        stats.procerr += 1
        stats.total += 1
      }

      if (d.rd_ctd == 0)
        stats.unread += 1
    })
    return stats
  }
  getDataSet = dt => {
    if (!!dt) {
      const qtdKeys = _.keys(dt)
      return {
        labels: qtdKeys,
        datasets: [
          {
            data: _.values(dt),
            //backgroundColor: qtdKeys.length > 1 ? getColors(qtdKeys.length, '#1e1efc', '#fc1e4a') : ['#1e1efc'],
            backgroundColor: qtdKeys.length > 1 ? getColorsByClass(qtdKeys.length) : ['#1e1efc'],
            borderWidth: 0
          }
        ]
      }
    } else {
      return {
        labels: ['Outros'],
        datasets: [
          {
            data: [0]
          }
        ]
      }
    }
  }
  updateDash = dts => {
    const dc = _.mapObject(_.groupBy(dts, 'doctype'), (v, k) => v.length)
    const sp = _.mapObject(_.groupBy(dts, 'supplier'), (v, k) => v.length)
    const bt = _.mapObject(_.groupBy(dts, 'boat'), (v, k) => v.length)
    const gp = _.mapObject(_.groupBy(dts, 'groupwithpendency'), (v, k) => v.length)
    const bp = _.mapObject(_.groupBy(dts, 'boatwithpendency'), (v, k) => v.length)
    this.setState({
      stats: this.getStats(this.state.rawdcs),
      docs: this.getDataSet(dc),
      suppliers: this.getDataSet(sp),
      boats: this.getDataSet(bt),
      groupswithpendency: this.getDataSet(gp),
      boatswithpendency: this.getDataSet(bp),
    })
  }

  updateDashUnread = dts_unread => {
    const dcu = _.mapObject(_.groupBy(dts_unread, 'doctype_unread'), (v, k) => v.length)
    const rg = _.mapObject(_.groupBy(dts_unread, 'region_unread'), (v, k) => v.length)
    this.setState({
      docs_unread: this.getDataSet(dcu),
      region_unread: this.getDataSet(rg)
    })
  }
  a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ selectedTab: newValue })
  }
  handleShowUnreadChange = (event) => {
    this.setState({showUnread:event.target.checked})
  }
  render() {
    return (
      <D className="col e aF scD" style={{ padding: '1rem' }} >
        <D className="col w aF">


          <D className="row w aF">
            <D className="e hide-md" />            
              {/*Card de Contagem - Início */}
              <D className="r2c e aF">
              <D className="row e aC">
                <DashboardCard title="Não Lido" value={this.state.stats.unread} color="#424242" icon={<SensorOccupied sx={{ fontSize:  28, color: '#fff' }} />} />

                <DashboardCard title="Não Processado" value={this.state.stats.nproc} color="#ccc" icon={<SyncDisabledOutlined sx={{ fontSize: 28, color: '#fff' }} />} />
              </D>
              <D className="row e aC">
                <DashboardCard title="Erro" value={this.state.stats.procerr} color="#fc1e4a" icon={<DangerousOutlined sx={{ fontSize: 28, color: ' #fff' }} />} />
                <DashboardCard title="Em Processamento" value={this.state.stats.onproc} color="#8d1ea3" icon={<Sync sx={{ fontSize: 28, color : '#fff' }} />} />
              </D>
              <D className="row e aC">
                <DashboardCard title="Processado" value={this.state.stats.proc} color="#1e1efc" icon={<CheckCircleOutline sx={{ fontSize: 28, color: '#fff' }} />} />
              </D>
            </D>
            {/*Card de Contagem - Fim */}
            <D className="e hide-md" />
          </D>

          <D h="0.7rem" />
        
          {/*Área de Intervalo das Datas - Início */}
            <D className="row w aC" p="2">
              <D className="row aC" style={{
                background: '#eeeeee',
                borderRadius: '0.5rem',
              }}>
            <MuiTooltip title={'Data inicial ' + (!!this.state.dataini ? moment(this.state.dataini).format('D/M/YY') : '')} arrow>
                  <IconButton
                    onClick={e => this.setState({ aproxIniDateMenu: e.currentTarget })}
                    color={!!this.state.dataini ? 'primary' : 'default'}
                    aria-controls={!!this.state.aproxIniDateMenu ? 'aproxDateMenu' : undefined}
                    aria-expanded={!!this.state.aproxIniDateMenu ? 'true' : undefined}>
                    <DateRangeOutlined />
                  </IconButton>
            </MuiTooltip>
            <Menu id="aproxIniDateMenu"
              anchorEl={this.state.aproxIniDateMenu}
              open={!!this.state.aproxIniDateMenu}
              onClose={() => this.setState({ aproxIniDateMenu: false })}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={this.state.dataini}
                  onChange={v => this.setState({ dataini: v })}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <DialogActions>
                <Button variant="outlined" color="primary"
                  onClick={() => this.setState({ dataini: null })}>Limpar data</Button>
                <Button variant="outlined" color="primary"
                  onClick={() => {
                    this.setState({ aproxIniDateMenu: null })
                    this.applyFilter({})
                  }}>Aplicar</Button>
              </DialogActions>
            </Menu>
            <D size="0.5rem" />
            <MuiTooltip title={'Data final ' + (!!this.state.datafim ? moment(this.state.datafim).format('D/M/YY') : '')} arrow>
                  <IconButton
                    onClick={e => this.setState({ aproxFimDateMenu: e.currentTarget })}
                    color={!!this.state.datafim ? 'primary' : 'default'}
                    aria-controls={!!this.state.aproxFimDateMenu ? 'aproxDateMenu' : undefined}
                    aria-expanded={!!this.state.aproxFimDateMenu ? 'true' : undefined}>
                    <DateRangeOutlined />
                  </IconButton>
            </MuiTooltip>
            <Menu id="aproxFimDateMenu"
              anchorEl={this.state.aproxFimDateMenu}
              open={!!this.state.aproxFimDateMenu}
              onClose={() => this.setState({ aproxFimDateMenu: false })}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={this.state.datafim}
                  onChange={v => this.setState({ datafim: v })}
                  renderInput={(params) => <TextField {...params} />} />
              </LocalizationProvider>
              <DialogActions>
                <Button variant="outlined" color="primary"
                  onClick={() => this.setState({ datafim: null })}>Limpar data</Button>
                <Button variant="outlined" color="primary"
                  onClick={() => {
                    this.setState({ aproxFimDateMenu: null })
                    this.applyFilter({})
                  }}>Aplicar</Button>
              </DialogActions>
            </Menu>
            </D>
          </D>
          {/*Área de Intervalo das Datas - Fim */}




        </D>




        <D h="1rem" />

        {/*Área de gráficos - Início */}
        <D className="col w aF scB">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={this.state.selectedTab} onChange={this.handleTabChange} aria-label="basic tabs example">
              <Tab label="Indicadores" icon={<PieChart />} iconPosition="start" {...this.a11yProps(0)} />
              <Tab label="Linha do Tempo" icon={<Timeline />} iconPosition="start" {...this.a11yProps(1)} /> 
            </Tabs>
          </Box>
          <DashboardTabPanel value={this.state.selectedTab} index={0}> {/* Indicadores */}
            <D className="col w aF scB">
                <FormGroup className="col w aF scB">
                    <FormControlLabel control={<Switch checked={this.state.showUnread} onChange={this.handleShowUnreadChange}  />} label={<Typography variant="overline" component="div" align="center" color="text.secondary" noWrap >Apenas Documentos Não Lidos</Typography>}/>
                </FormGroup>

           {/*Primeira linha de gráficos (Corresponde aos não lidos) - Início */}
           {this.state.showUnread === true && 
           <D className="row e aF">
             <D className="wrp e aF">
               {/*Primeiro Dashboard Graph*/}
               <D className="col e aF" style={{ margin: '5px', padding: '1rem', borderRadius: '1rem', backgroundColor: '#fff' }}>
                 <Button
                   variant="outlined"
                   onClick={e => this.setState({ docsUnreadMenu: e.currentTarget })}
                   color={!!this.state.selectedDocsUnread.length > 0 ? 'primary' : 'info'}>Tipos de documentos</Button>
                 <Menu id="docsUnreadMenu"
                   anchorEl={this.state.docsUnreadMenu}
                   open={!!this.state.docsUnreadMenu}
                   onClose={() => this.setState({ docsUnreadMenu: null })}>
                   <D className="col e aF scD scB hv" style={{ maxHeight: '30vh', padding: '0.3rem 1rem 0.3rem 0.3rem' }}>
                     {this.state.tiposDeDocsUnread.map(a => (
                       <D className="row aC" key={a} value={a} onClick={() => {
                         if (_.indexOf(this.state.selectedDocsUnread, a) > -1) {
                           this.applyFilter({ selectedDocsUnread: _.without(this.state.selectedDocsUnread, a) })
                         } else {
                           this.applyFilter({ selectedDocsUnread: _.union(this.state.selectedDocsUnread, [a]) })
                         }
                       }}>
                         <Checkbox checked={this.state.selectedDocsUnread.indexOf(a) > -1} />
                         <D e>{a}</D>
                       </D>
                     ))}
                   </D>
                 </Menu>
                 <DashboardGraph data={this.state.docs_unread} />          
               </D>
 
               {/*Segundo Dashboard Graph*/}
               <D className="col e aF" style={{ margin: '5px', padding: '1rem', borderRadius: '1rem', backgroundColor: '#fff' }}>
                 <Button
                   variant="outlined"
                   onClick={e => this.setState({ regUnreadMenu: e.currentTarget })}
                   color={!!this.state.selectedRegUnread.length > 0 ? 'primary' : 'info'}>{C.groupLabel}</Button>
                 <Menu id="regUnreadMenu"
                   anchorEl={this.state.regUnreadMenu}
                   open={!!this.state.regUnreadMenu}
                   onClose={() => this.setState({ regUnreadMenu: null })}>
                   <D className="col e aF scD scB hv" style={{ maxHeight: '30vh', padding: '0.3rem 1rem 0.3rem 0.3rem' }}>
                     {this.state.tiposDeRegUnread.map(a => (
                       <D className="row aC" key={a} value={a} onClick={() => {
                         if (_.indexOf(this.state.selectedRegUnread, a) > -1) {
                           this.applyFilter({ selectedRegUnread: _.without(this.state.selectedRegUnread, a) })
                         } else {
                           this.applyFilter({ selectedRegUnread: _.union(this.state.selectedRegUnread, [a]) })
                         }
                       }}>
                         <Checkbox checked={this.state.selectedRegUnread.indexOf(a) > -1} />
                         <D e>{a}</D>
                       </D>
                     ))}
                   </D>
                 </Menu>
                 <DashboardGraph data={this.state.region_unread} />
               </D>

             </D>
           </D>
           }
           {/*Primeira linha de gráficos (Corresponde aos não lidos) - Fim */}



          {/*Segunda linha de gráficos (Corresponde à todos os documentos, sem restrição de não lidos) - Início */}
          {this.state.showUnread === false &&
          <D className="row e aF">
            <D className="wrp e aF">
              {/*Primeiro Dashboard Graph*/}
              <D className="col e aF" style={{ margin: '5px', padding: '1rem', borderRadius: '1rem', backgroundColor: '#fff' }}>
                <Button
                  variant="outlined"
                  onClick={e => this.setState({ docsMenu: e.currentTarget })}
                  color={!!this.state.selectedDocs.length > 0 ? 'primary' : 'info'}>Tipos de documentos</Button>
                <Menu id="docsMenu"
                  anchorEl={this.state.docsMenu}
                  open={!!this.state.docsMenu}
                  onClose={() => this.setState({ docsMenu: null })}>
                  <D className="col e aF scD scB hv" style={{ maxHeight: '30vh', padding: '0.3rem 1rem 0.3rem 0.3rem' }}>
                    {this.state.tiposDeDocs.map(a => (
                      <D className="row aC" key={a} value={a} onClick={() => {
                        if (_.indexOf(this.state.selectedDocs, a) > -1) {
                          this.applyFilter({ selectedDocs: _.without(this.state.selectedDocs, a) })
                        } else {
                          this.applyFilter({ selectedDocs: _.union(this.state.selectedDocs, [a]) })
                        }
                      }}>
                        <Checkbox checked={this.state.selectedDocs.indexOf(a) > -1} />
                        <D e>{a}</D>
                      </D>
                    ))}
                  </D>
                </Menu>
                <DashboardGraph data={this.state.docs} />
              </D>

              {/*Segundo Dashboard Graph */}
              <D className="col e aF" style={{ margin: '5px', padding: '1rem', borderRadius: '1rem', backgroundColor: '#fff' }}>
                <Button
                  variant="outlined"
                  onClick={e => this.setState({ fornecMenu: e.currentTarget })}
                  color={!!this.state.selectedFornec.length > 0 ? 'primary' : 'info'}>Fornecedores</Button>
                <Menu
                  anchorEl={this.state.fornecMenu}
                  id="docsMenu"
                  open={!!this.state.fornecMenu}
                  onClose={() => this.setState({ fornecMenu: null })}>
                  <D className="col e aF scD scB hv" style={{ maxHeight: '30vh', padding: '0.3rem 1rem 0.3rem 0.3rem' }}>
                    {this.state.tiposDeFornec.map(a => (
                      <D className="row aC" key={a} value={a} onClick={() => {
                        if (_.indexOf(this.state.selectedFornec, a) > -1) {
                          this.applyFilter({ selectedFornec: _.without(this.state.selectedFornec, a) })
                        } else {
                          this.applyFilter({ selectedFornec: _.union(this.state.selectedFornec, [a]) })
                        }
                      }}>
                        <Checkbox checked={this.state.selectedFornec.indexOf(a) > -1} />
                        <D e>{a}</D>
                      </D>
                    ))}
                  </D>
                </Menu>
                <DashboardGraph data={this.state.suppliers} />
              </D>

            </D>
          </D>
          }
          {/*Segunda linha de gráficos (Corresponde à todos os documentos, sem restrição de não lidos) - Fim */}
          {/*Terceira linha de gráficos (Corresponde à todos os documentos, sem restrição de não lidos) - Início */}
          {this.state.showUnread === false && 
          <D className="e row aF">
            <D className="wrp e aF">
              {/*Primeiro Dashboard Graph */}
              <D className="col e aF" style={{ margin: '5px', padding: '1rem', borderRadius: '1rem', backgroundColor: '#fff' }}>
                <Button
                  variant="outlined"
                  onClick={e => this.setState({ embMenu: e.currentTarget })}
                  color={!!this.state.selectedEmb.length > 0 ? 'primary' : 'info'}>Embarcações</Button>
                <Menu
                  anchorEl={this.state.embMenu}
                  id="docsMenu"
                  open={!!this.state.embMenu}
                  onClose={() => this.setState({ embMenu: null })}>
                  <D className="col e aF scD scB hv" style={{ maxHeight: '30vh', padding: '0.3rem 1rem 0.3rem 0.3rem' }}>
                    {this.state.tiposDeEmb.map(a => (
                      <D className="row aC" key={a} value={a} onClick={() => {
                        if (_.indexOf(this.state.selectedEmb, a) > -1) {
                          this.applyFilter({ selectedEmb: _.without(this.state.selectedEmb, a) })
                        } else {
                          this.applyFilter({ selectedEmb: _.union(this.state.selectedEmb, [a]) })
                        }
                      }}>
                        <Checkbox checked={this.state.selectedEmb.indexOf(a) > -1} />
                        <D e>{a}</D>
                      </D>
                    ))}
                  </D>
                </Menu>
                <DashboardGraph data={this.state.boats} legendPosition="bottom" />
              </D>

              {/*Segundo Dashboard Graph */}
              <D className="col e aF" style={{ margin: '5px', padding: '1rem', borderRadius: '1rem', backgroundColor: '#fff' }}>
                <Button
                  variant="outlined"
                  onClick={e => this.setState({ grupoPendenciaMenu: e.currentTarget })}
                  color={!!this.state.selectedGrupoPendencia.length > 0 ? 'primary' : 'info'}>Pendências em Filial-Grupo</Button>
                <Menu
                  anchorEl={this.state.grupoPendenciaMenu}
                  id="docsMenuPendencia"
                  open={!!this.state.grupoPendenciaMenu}
                  onClose={() => this.setState({ grupoPendenciaMenu: null })}>
                  <D className="col e aF scD scB hv" style={{ maxHeight: '30vh', padding: '0.3rem 1rem 0.3rem 0.3rem' }}>
                    {this.state.tiposDeGrupoPendencia.map(a => (
                      <D className="row aC" key={a} value={a} onClick={() => {
                        if (_.indexOf(this.state.selectedGrupoPendencia, a) > -1) {
                          this.applyFilter({ selectedGrupoPendencia: _.without(this.state.selectedGrupoPendencia, a) })
                        } else {
                          this.applyFilter({ selectedGrupoPendencia: _.union(this.state.selectedGrupoPendencia, [a]) })
                        }
                      }}>
                        <Checkbox checked={this.state.selectedGrupoPendencia.indexOf(a) > -1} />
                        <D e>{a}</D>
                      </D>
                    ))}
                  </D>
                </Menu>
                <DashboardGraph data={this.state.groupswithpendency} />
              </D>
              

            </D>
          </D>
          }
          {/*Terceira linha de gráficos (Corresponde à todos os documentos, sem restrição de não lidos) - Fim */}










          {/*Quarta linha de gráficos (Corresponde à todos os documentos, sem restrição de não lidos) - Início */}
          {this.state.showUnread === false && 
          <D className="e row aF">
            <D className="wrp e aF">
              {/*Primeiro Dashboard Graph */}
              <D className="col e aF" style={{ margin: '5px', padding: '1rem', borderRadius: '1rem', backgroundColor: '#fff' }}>
                <Button
                  variant="outlined"
                  onClick={e => this.setState({ embarcacaoPendenciaMenu: e.currentTarget })}
                  color={!!this.state.selectedEmbarcacaoPendencia.length > 0 ? 'primary' : 'info'}>Pendências em Embarcação</Button>
                <Menu
                  anchorEl={this.state.embarcacaoPendenciaMenu}
                  id="docsMenuPendenciaEmbarcacao"
                  open={!!this.state.embarcacaoPendenciaMenu}
                  onClose={() => this.setState({ embarcacaoPendenciaMenu: null })}>
                  <D className="col e aF scD scB hv" style={{ maxHeight: '30vh', padding: '0.3rem 1rem 0.3rem 0.3rem' }}>
                    {this.state.tiposDeEmbarcacaoPendencia.map(a => (
                      <D className="row aC" key={a} value={a} onClick={() => {
                        if (_.indexOf(this.state.selectedEmbarcacaoPendencia, a) > -1) {
                          this.applyFilter({ selectedEmbarcacaoPendencia: _.without(this.state.selectedEmbarcacaoPendencia, a) })
                        } else {
                          this.applyFilter({ selectedEmbarcacaoPendencia: _.union(this.state.selectedEmbarcacaoPendencia, [a]) })
                        }
                      }}>
                        <Checkbox checked={this.state.selectedEmbarcacaoPendencia.indexOf(a) > -1} />
                        <D e>{a}</D>
                      </D>
                    ))}
                  </D>
                </Menu>
                <DashboardGraph data={this.state.boatswithpendency} />
              </D>              

              {/*Segundo Dashboard Graph */}

              

            </D>
          </D>
          }
          {/*Quarta linha de gráficos (Corresponde à todos os documentos, sem restrição de não lidos) - Fim */}















            </D>
          </DashboardTabPanel>
          <DashboardTabPanel value={this.state.selectedTab} index={1}> {/* Linha do Tempo*/}
            <Filter api={this.props.api} dataInicio={moment(this.state.dataini).format('DD/MM/YYYY')} dataFim={ moment(this.state.datafim).format('DD/MM/YYYY')}
            user={this.props.user} alert={this.props.alert}
            />
          </DashboardTabPanel>
          </D>
        {/*Área de gráficos - Fim */}
      </D >
    )
  }
}
